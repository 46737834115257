@import "../base/color-variables.scss";
@import "../base/grid.scss";

$radioButtonHeight: 40px;
$radioButtonInnerHeight: 24px;

.gumtree-radio {
    padding: $gutter-size--small 0;
}

.radio-switch {
    + label {
        margin-bottom: $gutter-size;
        padding: $gutter-size--xsmall 0 0 $radioButtonHeight;
        position: relative;
        vertical-align: middle;
        display: inline-block;

        a {
            color: $color-main-tertiary;
        }

        &:before {
            background: $white;
            border-radius: $radioButtonHeight;
            border: 1px solid $color-border--medium;
            box-sizing: content-box;
            content: "";
            cursor: pointer;
            display: table-cell;
            height: $radioButtonInnerHeight;
            left: 0;
            margin-top: -$gutter-size--medium;
            position: absolute;
            top: 50%;
            vertical-align: middle;
            width: $radioButtonInnerHeight;
        }
    }

    &:checked + label {
        &:before {
            background: $green; /* Old browsers */
            background: radial-gradient(
                ellipse at center,
                $green 0%,
                $green 39%,
                $color-background--neutral 44%,
                $color-background--neutral 100%
            );
        }
    }
}
