@import "../base/color-variables.scss";
@import "../base/colors.scss";
@import "../base/grid.scss";
@import "../base/boxes.scss";

$input-checkbox-size: 30px;

@mixin strengthCircles($clr1, $clr2, $clr3, $clr4) {
    content: "";
    background: radial-gradient(circle, $clr1 25%, transparent 30%) -4px 5px,
        radial-gradient(circle, $clr2 25%, transparent 30%) 6px 5px,
        radial-gradient(circle, $clr3 25%, transparent 30%) 16px 5px,
        radial-gradient(circle, $clr4 25%, transparent 30%) 26px 5px;
    background-size: 20px 10px;
    background-repeat: no-repeat;
}

.input {
    &-number,
    &-text,
    &-password,
    &-tel,
    &-email {
        display: block;
        width: 100%;
        border: $box-border-width solid $bark--20;
        border-radius: $box-border-radius;
        box-sizing: border-box;
        color: inherit;
        font-family: inherit;
        font-size: 16px;
        line-height: 20px;
        height: $box-height;
        padding: $gutter-size--small $box-height $gutter-size--small $gutter-size;
        transition: border-color 0.222s ease-out;
        appearance: none;
    }

    &-number {
        -moz-appearance: textfield;

        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }

    &::placeholder {
        color: $color-font--quaternary;
    }

    &::-ms-clear {
        display: none;
    }

    &:focus {
        outline: none;
        border: 1px solid $bark--80;

        &::placeholder {
            color: $bark--40;
            transition: color 0.222s ease-out;
        }

        &::-ms-clear,
        &::-webkit-inner-spin-button {
            display: none;
        }

        &:focus {
            outline: none;
            border: 1px solid $bark--80;

            &::placeholder {
                color: $bark--20;
            }
        }

        &:disabled {
            border: 1px solid $bark--10;
            background: $branch--10;
            color: $bark--40;
        }
    }

    &-checkbox {
        cursor: pointer;
        height: $input-checkbox-size;
        margin: 0;
        position: relative;
        visibility: hidden;
        width: $input-checkbox-size;
        z-index: 0;

        &:before,
        &:after {
            height: inherit;
            position: absolute;
            visibility: visible;
            width: inherit;
        }

        &:before {
            background: #fff;
            border-radius: 3px;
            border: 1px solid $branch--40;
            color: rgba($color-main-primary, 0);
            content: "\e630";
            font-family: gumicon, sans-serif;
            font-size: 20px;
            left: 0;
            line-height: $input-checkbox-size;
            text-align: center;
            top: 0;
            transition: color 50ms ease-out;
            z-index: 1;
        }

        &:after {
            background-color: $white;
            content: "";
            filter: blur(1px);
            height: 18px;
            right: 6px;
            top: 6px;
            transition: width 400ms cubic-bezier(0.3, 0, 0.735, 0.045);
            width: 18px;
            z-index: 2;
        }

        &:checked {
            &:before {
                color: rgba($color-main-primary, 1);
            }

            &:after {
                width: 0;
            }
        }

        &:hover {
            &:before {
                border-color: $bark--80;
            }
        }
    }
}

.error-messages {
    color: $red;
}

label {
    font-weight: 600;
}

.reveal-button-show {
    position: absolute;
    top: 32px;
    right: 0;
    padding: 10px 8px;
    cursor: pointer;
    min-width: 55px;
    border: none;
    background: none;

    &:before {
        content: "";
        border-left: 1px solid $bark--20;
        padding-right: 12px;
    }
}

.error-additional-info {
    border: none;
    background: none;
    padding: 0;
    font-weight: 600;
    text-decoration: underline;
    color: $red;
    cursor: pointer;
    padding-left: $gutter-size--small;
}

.password-strength {
    display: block;

    &.veryweak:before {
        content: "Very weak";
    }

    &.veryweak:after {
        @include strengthCircles(green, gray, gray, gray);
    }

    &.weak:before {
        content: "Weak";
    }

    &.weak:after {
        @include strengthCircles(green, green, gray, gray);
    }

    &.ok:before {
        content: "Ok";
    }

    &.ok:after {
        @include strengthCircles(green, green, green, gray);
    }

    &.strong:before {
        content: "Strong";
    }

    &.strong:after {
        @include strengthCircles(green, green, green, green);
    }

    &:before {
        position: absolute;
        left: 50px;
        bottom: -24px;
    }

    &:after {
        position: absolute;
        left: 0;
        bottom: -24px;
        width: 50px;
        height: 20px;
    }
}

.input-clear {
    color: $bark--60;
    position: absolute;
    top: 0;
    right: 0;
}
