@import "../base/grid.scss";
@import "../base/fonts.scss";

.breadcrumbs {
    display: inline;
    list-style-type: none;
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 0;
    white-space: nowrap;
}

.breadcrumbs-container {
    font-size: $font-size--small;
    font-weight: $font-weight--normal;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }

    .breadcrumbs-link {
        display: inline-block;

        &:not(:last-of-type):after {
            content: "/";
            padding: 0 $gutter-size;
            font-weight: $font-weight--bolder;
        }
    }
}
