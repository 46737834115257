img:not(.initial) {
    transition: opacity 0.2s;
}

img.initial,
img.loaded,
img.error {
    opacity: 1;
}

img.lazyload,
img.lazyloading {
    opacity: 0;
}
