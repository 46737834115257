@import "../base/color-variables.scss";
@import "../base/grid.scss";
@import "../base/fonts.scss";

.breadcrumbs-container {
    .icon {
        font-size: $font-size--smaller;
        vertical-align: baseline;
        color: $color-text--link;
    }

    .back-link {
        font-weight: $font-weight--bold;

        .link {
            padding-right: $gutter-size;
        }

        &:after {
            margin-right: $gutter-size;
            content: "|";
            font-weight: $font-weight--bold;
            color: black;
        }
    }
}
