@import "../base/color-variables.scss";
@import "../base/grid.scss";
@import "../base/boxes.scss";

%field--full-width {
    display: block;
    width: 100%;
}

%field--inline {
    display: inline-block;
    width: auto;
}

.field--inline {
    @extend %field--inline;

    label {
        @extend %field--inline;
        margin-right: $gutter-size;
        margin-bottom: 0;
    }

    & + & {
        margin-left: $gutter-size--large;
    }
}

.field {
    @extend %field--full-width;
    margin-bottom: $gutter-size--large;

    label {
        @extend %field--full-width;
        margin-bottom: $gutter-size;
        transition: color 0.222s ease-out;
    }

    .has-submitted &.is-invalid {
        select,
        input {
            border: 1px solid $red;
        }

        label {
            color: $red;
        }
    }
}

.field-error {
    color: $red;
    display: none;
    margin-top: $gutter-size--small;

    .has-submitted .is-invalid & {
        @extend %field--full-width;
    }

    .has-submitted .is-invalid.field--inline & {
        @extend %field--inline;
        margin-left: $gutter-size;
        margin-top: 0;
    }
}
